import { AuthModel } from "./_models";
import axios from "axios";
import { notification } from "antd";
import dayjs from "dayjs";

const API_URL = process.env.REACT_APP_API_URL;
const REFRESH_TOKEN_URL = `${API_URL}/auth/refresh-token`;
// /v1.0/auth/refresh-token
let isRequesting = false;

const AUTH_LOCAL_STORAGE_KEY = "kt-auth-react-v";

function resetIsRequesting() {
  isRequesting = false;
}
const getAuth = (): any | undefined => {
  if (!localStorage) {
    return;
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  if (!lsValue) {
    return;
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel;
    if (auth) {
      // You can easily check auth_token expiration also
      return auth;
    }
  } catch (error) {
    // console.error("AUTH LOCAL STORAGE PARSE ERROR", error);
  }
};

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return;
  }

  try {
    const lsValue = JSON.stringify(auth);
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue);
  } catch (error) {
    // console.error("AUTH LOCAL STORAGE SAVE ERROR", error);
  }
};

const removeAuth = () => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
  } catch (error) {
    // console.error("AUTH LOCAL STORAGE REMOVE ERROR", error);
  }
};

const refreshToken = () => {
  // const refresh_token = getAuth()?.meta?.refresh_token?.token;
  const refresh_token = getAuth()?.refreshToken;
  isRequesting = true;
  return axios
    .post(`${REFRESH_TOKEN_URL}`, { refreshToken: refresh_token })
    .then((response: any) => response.data)
    .then((response: any) => response.data);
};

export function setupAxios(axios: any) {
  const i18nConfigString = localStorage.getItem("i18nConfig");

  const i18nConfig =
    i18nConfigString && JSON.parse(localStorage.getItem("i18nConfig") || "");
  const selectedLang = i18nConfig?.selectedLang == "ko" ? "ko" : "en";

  axios.defaults.headers.Accept = "application/json";
  axios.interceptors.request.use(
    (config: {
      headers: {
        Authorization: string;
        "Accept-Language": string;
        "Accept-Datetime": string;
      };
    }) => {
      const auth = getAuth();
      // const token = auth?.meta?.access_token?.token;`
      const token = auth?.accessToken;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
        config.headers[`Accept-Language`] = selectedLang;
        config.headers[`Accept-Datetime`] = dayjs().format(
          "YYYY-MM-DD HH:mm:ss Z"
        );
      }
      return config;
    },
    (err: any) => Promise.reject(err)
  );

  axios.interceptors.response.use(
    (response: any) => {
      return response;
    },
    async function (error: any) {
      if (error?.response?.status === 500 || error?.response?.status === 403)
        return;
      const originalRequest = error.config;

      if (originalRequest._retry || originalRequest.url.includes("refresh")) {
        if (error?.response?.data.message) {
          notification.destroy();
          notification.error({
            message: `${error?.response?.data?.message || "Error"}`,
            duration: 3,
            //style: { backgroundColor: "#f1416c", color: "white" },
          });
        }
        removeAuth();
        window.location.href = "/auth";
      }
      // console.log('aaaaa>>>refreshToken', error.response);
      // console.log('aaaaa>>>originalRequest', originalRequest);
      if (
        (error?.response?.status === 401 || error?.response?.status === 403) &&
        !originalRequest._retry
      ) {
        if (!isRequesting) {
          const res = await refreshToken();
          resetIsRequesting();
          const new_access_token = res?.access_token?.token;
          setAuth({
            ...getAuth(),
            meta: {
              ...getAuth()?.meta,
              access_token: { token: new_access_token },
            },
          });

          originalRequest._retry = true;
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + new_access_token;
          return axios(originalRequest);
        } else {
          if (originalRequest.url.includes("/me")) removeAuth();
        }
      } else {
        if (
          error?.response?.status === 500 ||
          error?.response?.status === 404
        ) {
          return;
        }
        let messageError =
          error.response?.data?.message || error.response?.data?.error?.message;
        if (messageError) {
          notification.destroy();
          notification.error({
            message: `${messageError || "Error"}`,
            duration: 3,
            //style: { backgroundColor: "#f1416c", color: "white" },
          });
        }
        return error.response;
      }
      // return Promise.reject(error);

      // return error.response;
      return Promise.reject(error);
    }
  );
}

export { getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY };
